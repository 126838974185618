var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    [
      _c(
        "a-card",
        { attrs: { bordered: false } },
        [
          _c(
            "div",
            { staticClass: "table-page-search-wrapper" },
            [
              _c(
                "a-form",
                { attrs: { layout: "inline" } },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: 48 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { md: 8, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "车辆编号", prop: "vehicleId" } },
                            [
                              _c("a-input", {
                                attrs: {
                                  placeholder: "请输入车辆编号",
                                  "allow-clear": "",
                                },
                                model: {
                                  value: _vm.queryParam.vehicleId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParam, "vehicleId", $$v)
                                  },
                                  expression: "queryParam.vehicleId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { md: 8, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "开始时间",
                                prop: "taskStartTime",
                              },
                            },
                            [
                              _c("a-range-picker", {
                                staticClass: "margin-left",
                                attrs: {
                                  "show-time": { format: "HH:mm" },
                                  format: "YYYY-MM-DD HH:mm",
                                  "value-format": "YYYY-MM-DD HH:mm",
                                  placeholder: ["起", "止"],
                                },
                                model: {
                                  value: _vm.taskStartTimeRange,
                                  callback: function ($$v) {
                                    _vm.taskStartTimeRange = $$v
                                  },
                                  expression: "taskStartTimeRange",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.advanced
                        ? [
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "结束时间",
                                      prop: "taskEndTime",
                                    },
                                  },
                                  [
                                    _c("a-range-picker", {
                                      staticClass: "margin-left",
                                      attrs: {
                                        "show-time": { format: "HH:mm" },
                                        format: "YYYY-MM-DD HH:mm",
                                        "value-format": "YYYY-MM-DD HH:mm",
                                        placeholder: ["起", "止"],
                                      },
                                      model: {
                                        value: _vm.taskEndTimeRange,
                                        callback: function ($$v) {
                                          _vm.taskEndTimeRange = $$v
                                        },
                                        expression: "taskEndTimeRange",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "任务类型",
                                      prop: "taskType",
                                    },
                                  },
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          placeholder: "请选择任务类型",
                                          "allow-clear": "",
                                        },
                                        model: {
                                          value: _vm.queryParam.taskType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryParam,
                                              "taskType",
                                              $$v
                                            )
                                          },
                                          expression: "queryParam.taskType",
                                        },
                                      },
                                      _vm._l(
                                        _vm.taskTypeOptions,
                                        function (d, index) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: index,
                                              attrs: { value: d.dictValue },
                                            },
                                            [_vm._v(_vm._s(d.dictLabel))]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "任务状态",
                                      prop: "taskState",
                                    },
                                  },
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          placeholder: "请选择任务状态",
                                          "allow-clear": "",
                                        },
                                        model: {
                                          value: _vm.queryParam.taskState,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryParam,
                                              "taskState",
                                              $$v
                                            )
                                          },
                                          expression: "queryParam.taskState",
                                        },
                                      },
                                      _vm._l(
                                        _vm.taskStateOptions,
                                        function (d, index) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: index,
                                              attrs: { value: d.dictValue },
                                            },
                                            [_vm._v(_vm._s(d.dictLabel))]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _c(
                        "a-col",
                        { attrs: { md: (!_vm.advanced && 8) || 24, sm: 24 } },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "table-page-search-submitButtons",
                              style:
                                (_vm.advanced && {
                                  float: "right",
                                  overflow: "hidden",
                                }) ||
                                {},
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.handleQuery },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "search" } }),
                                  _vm._v("查询"),
                                ],
                                1
                              ),
                              _c(
                                "a-button",
                                {
                                  staticStyle: { "margin-left": "8px" },
                                  on: { click: _vm.resetQuery },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "redo" } }),
                                  _vm._v("重置"),
                                ],
                                1
                              ),
                              _c(
                                "a",
                                {
                                  staticStyle: { "margin-left": "8px" },
                                  on: { click: _vm.toggleAdvanced },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.advanced ? "收起" : "展开") +
                                      " "
                                  ),
                                  _c("a-icon", {
                                    attrs: {
                                      type: _vm.advanced ? "up" : "down",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-toolbar",
            {
              attrs: { loading: _vm.loading, refresh: "", tid: "1" },
              on: { refreshQuery: _vm.getList },
            },
            [_c("div", { staticClass: "table-operations" })]
          ),
          _c("create-form", {
            ref: "createForm",
            attrs: {
              bizStateOptions: _vm.bizStateOptions,
              taskStateOptions: _vm.taskStateOptions,
              vehicleRemoteCtlResultOptions: _vm.vehicleRemoteCtlResultOptions,
            },
            on: { ok: _vm.getList },
          }),
          _vm.viewTaskRecordVisible
            ? _c("task-report-modal", {
                attrs: {
                  position: { top: "0%", left: 270 },
                  vehicleId: _vm.curVehicleId,
                  params: _vm.curParams,
                  isShowModal: _vm.viewTaskRecordVisible,
                },
                on: {
                  closeViewTaskModal: function ($event) {
                    _vm.viewTaskRecordVisible = false
                  },
                },
              })
            : _vm._e(),
          _c("a-table", {
            attrs: {
              loading: _vm.loading,
              size: _vm.tableSize,
              rowKey: "id",
              tid: "1",
              columns: _vm.columns,
              "data-source": _vm.list,
              pagination: false,
            },
            scopedSlots: _vm._u([
              {
                key: "taskStartTime",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(
                      " " + _vm._s(_vm.parseTime(record.taskStartTime)) + " "
                    ),
                  ])
                },
              },
              {
                key: "taskEndTime",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(
                      " " + _vm._s(_vm.parseTime(record.taskEndTime)) + " "
                    ),
                  ])
                },
              },
              {
                key: "operation",
                fn: function (text, record) {
                  return _c(
                    "span",
                    {},
                    [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.$refs.createForm.handleUpdate(
                                record,
                                undefined
                              )
                            },
                          },
                        },
                        [
                          _c("a-icon", { attrs: { type: "search" } }),
                          _vm._v("查看 "),
                        ],
                        1
                      ),
                      _c("a-divider", { attrs: { type: "vertical" } }),
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.handleTaskReport(record)
                            },
                          },
                        },
                        [
                          _c("a-icon", { attrs: { type: "table" } }),
                          _vm._v("任务报告 "),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                },
              },
              {
                key: "state",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(" " + _vm._s(_vm.stateFormat(record)) + " "),
                  ])
                },
              },
              {
                key: "taskState",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(" " + _vm._s(_vm.taskStateFormat(record)) + " "),
                  ])
                },
              },
            ]),
          }),
          _c("a-pagination", {
            staticClass: "ant-table-pagination",
            attrs: {
              "show-size-changer": "",
              "show-quick-jumper": "",
              current: _vm.queryParam.pageNum,
              total: _vm.total,
              "page-size": _vm.queryParam.pageSize,
              showTotal: function (total) {
                return "共 " + total + " 条"
              },
            },
            on: {
              showSizeChange: _vm.onShowSizeChange,
              change: _vm.changeSize,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }