<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="车辆编号" prop="vehicleId">
                <a-input v-model="queryParam.vehicleId" placeholder="请输入车辆编号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="开始时间" prop="taskStartTime">
                <a-range-picker
                  class="margin-left"
                  :show-time="{ format: 'HH:mm' }"
                  format="YYYY-MM-DD HH:mm"
                  value-format="YYYY-MM-DD HH:mm"
                  :placeholder="['起', '止']"
                  v-model="taskStartTimeRange"
                />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="结束时间" prop="taskEndTime">
                  <a-range-picker
                    class="margin-left"
                    :show-time="{ format: 'HH:mm' }"
                    format="YYYY-MM-DD HH:mm"
                    value-format="YYYY-MM-DD HH:mm"
                    :placeholder="['起', '止']"
                    v-model="taskEndTimeRange"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="任务类型" prop="taskType">
                  <a-select
                    placeholder="请选择任务类型"
                    v-model="queryParam.taskType"
                    style="width: 100%"
                    allow-clear
                  >
                    <a-select-option v-for="(d, index) in taskTypeOptions" :key="index" :value="d.dictValue">{{
                      d.dictLabel
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="任务状态" prop="taskState">
                  <a-select
                    placeholder="请选择任务状态"
                    v-model="queryParam.taskState"
                    style="width: 100%"
                    allow-clear
                  >
                    <a-select-option v-for="(d, index) in taskStateOptions" :key="index" :value="d.dictValue">{{
                      d.dictLabel
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <!-- 操作 -->
        <div class="table-operations">
          <!-- <a-button
            type="dashed"
            shape="circle"
            :loading="loading"
            :style="{float: 'right'}"
            icon="reload"
            @click="getList" /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :bizStateOptions="bizStateOptions"
        :taskStateOptions="taskStateOptions"
        :vehicleRemoteCtlResultOptions="vehicleRemoteCtlResultOptions"
        @ok="getList"
      />
      <task-report-modal
        :position="{ top: '0%', left: 270 }"
        v-if="viewTaskRecordVisible"
        :vehicleId="curVehicleId"
        :params="curParams"
        :isShowModal="viewTaskRecordVisible"
        @closeViewTaskModal="viewTaskRecordVisible=false"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :pagination="false">
        <span slot="taskStartTime" slot-scope="text, record">
          {{ parseTime(record.taskStartTime) }}
        </span>
        <span slot="taskEndTime" slot-scope="text, record">
          {{ parseTime(record.taskEndTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.createForm.handleUpdate(record, undefined)">
            <a-icon type="search" />查看
          </a>
          <a-divider type="vertical" />
          <a @click="handleTaskReport(record)">
            <a-icon type="table" />任务报告
          </a>
        </span>
        <!-- 业务状态 -->
        <span slot="state" slot-scope="text, record">
          {{ stateFormat(record) }}
        </span>
        <!-- 任务状态 -->
        <span slot="taskState" slot-scope="text, record">
          {{ taskStateFormat(record) }}
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listRecord } from '@/api/iot/vehicleTaskRecord'
import CreateForm from './modules/CreateForm'
import { formatTime } from '@/utils/formatHelper'
import TaskReportModal from './modules/TaskReportModal'
export default {
  name: 'VehicleRecord',
  components: {
    CreateForm,
    TaskReportModal
  },
  data () {
    return {
      curParams: null,
      curVehicleId: '',
      viewTaskRecordVisible: false,
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        raTaskId: null,
        vehicleId: null,
        taskSchedulerId: null,
        taskStartTime: null,
        taskEndTime: null,
        vehicleTaskId: null,
        mapId: null,
        mapVersion: null,
        taskProgress: null,
        taskExeId: null,
        state: null,
        taskStart: null,
        taskState: null,
        seq: null,
        status: null,
        lon: null,
        lat: null,
        finishedOdom: null,
        finishedTime: null,
        vehicleStartTime: null,
        vehicleEndTime: null,
        taskFinishedFlag: null,
        taskPauseInfos: null,
        tasksNumberDay: null,
        tasksExeNumberDay: null,
        tenantId: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '车辆编号',
          dataIndex: 'vehicleId',
          ellipsis: true,
          align: 'center',
          width: '80px'
        },
        {
          title: '开始时间',
          dataIndex: 'taskStartTime',
          scopedSlots: { customRender: 'taskStartTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '结束时间',
          dataIndex: 'taskEndTime',
          scopedSlots: { customRender: 'taskEndTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '地图ID',
          dataIndex: 'mapId',
          ellipsis: true,
          align: 'center'

        },
        {
          title: '地图版本',
          dataIndex: 'mapVersion',
          ellipsis: true,
          align: 'center',
          width: '80px'
        },
        {
          title: '任务进度',
          dataIndex: 'taskProgress',
          customRender: (text, record, index) => {
            return record.taskProgress + '%'
          },
          ellipsis: true,
          align: 'center',
          width: '80px'
        },
        {
          title: '任务状态',
          dataIndex: 'taskState',
          scopedSlots: { customRender: 'taskState' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '任务里程\n(公里)',
          dataIndex: 'finishedOdom',
          ellipsis: true,
          customRender: (text, record, index) => {
            return record.finishedOdom > 0 ? (record.finishedOdom / 1000).toFixed(3) : ''
          },
          align: 'center',
          width: '80px'
        },
        {
          title: '任务时长\n(h:m:s)',
          dataIndex: 'finishedTime',
          ellipsis: true,
          customRender: (text, record, index) => {
            return record.finishedTime ? formatTime(record.finishedTime) : ''
          },
          align: 'center',
          width: '80px'
        },
        {
          title: '任务开始',
          dataIndex: 'vehicleStartTime',
          ellipsis: true,
          customRender: (text, record, index) => {
            return this.parseTime(record.vehicleStartTime)
          },
          align: 'center'
        },
        {
          title: '任务结束',
          dataIndex: 'vehicleEndTime',
          ellipsis: true,
          customRender: (text, record, index) => {
            return this.parseTime(record.vehicleEndTime)
          },
          align: 'center'
        },
        {
          title: '当天完成的任务次数',
          dataIndex: 'tasksNumberDay',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '当天执行的任务次数',
          dataIndex: 'tasksExeNumberDay',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '15%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      // 任务状态
      taskStateOptions: [],
      // 任务类型
      taskTypeOptions: [],
      // 下发结果
      vehicleRemoteCtlResultOptions: [],
      // 业务状态
      bizStateOptions: [],
      taskStartTimeRange: [],
      taskEndTimeRange: [],
      statusOptions: []
    }
  },
  filters: {
  },
  created () {
    this.getData()
  },
  computed: {
  },
  watch: {
  },
  methods: {
     handleTaskReport(record) {
      const stateOptions = this.bizStateOptions
      const taskStateOptions = this.taskStateOptions
      const taskTypeOptions = this.taskTypeOptions
      const statusOptions = this.bizStateOptions
       this.curParams = {
        ...record,
          stateOptions,
          taskStateOptions,
          taskTypeOptions,
          statusOptions
       }
       this.curVehicleId = record.vehicleId
       this.viewTaskRecordVisible = true
     },
     async getData() {
      await this.getDicts('iot_task_taskState').then((response) => {
        this.taskStateOptions = response.data
      })
      await this.getDicts('iot_vehicle_task_type').then((response) => {
        this.taskTypeOptions = response.data
      })
      // 车端回传结果状态
      await this.getDicts('iot_vehicle_remoteCtlResult').then((response) => {
        this.vehicleRemoteCtlResultOptions = response.data
      })
      // 业务状态
      await this.getDicts('iot_task_state').then((response) => {
        this.bizStateOptions = response.data
      })
      this.getList()
    },
    stateFormat(row, column) {
      return this.selectDictLabel(this.bizStateOptions, row.state)
    },
    taskStateFormat(row, column) {
       return this.selectDictLabel(this.taskStateOptions, row.taskState)
    },

    /** 查询任务下发记录列表 */
    getList () {
      this.loading = true
        this.queryParam.params = {}
      if (
        this.taskStartTimeRange !== null &&
        this.taskStartTimeRange !== '' &&
        this.taskStartTimeRange.length !== 0
      ) {
        this.queryParam.params['beginTaskStartTime'] = this.taskStartTimeRange[0]
        this.queryParam.params['endTaskStartTime'] = this.taskStartTimeRange[1]
      }
       if (
        this.taskEndTimeRange !== null &&
        this.taskEndTimeRange !== '' &&
        this.taskEndTimeRange.length !== 0
      ) {
        this.queryParam.params['beginTaskEndTime'] = this.taskEndTimeRange[0]
        this.queryParam.params['endTaskEndTime'] = this.taskEndTimeRange[1]
      }

      listRecord(this.queryParam).then(response => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.taskStartTimeRange = []
      this.taskEndTimeRange = []
      this.queryParam = {
        raTaskId: undefined,
        vehicleId: undefined,
        taskSchedulerId: undefined,
        taskStartTime: undefined,
        taskEndTime: undefined,
        vehicleTaskId: undefined,
        mapId: undefined,
        mapVersion: undefined,
        taskProgress: undefined,
        taskExeId: undefined,
        state: undefined,
        taskStart: undefined,
        taskState: undefined,
        seq: undefined,
        status: undefined,
        lon: undefined,
        lat: undefined,
        finishedOdom: undefined,
        finishedTime: undefined,
        vehicleStartTime: undefined,
        vehicleEndTime: undefined,
        taskFinishedFlag: undefined,
        taskPauseInfos: undefined,
        tasksNumberDay: undefined,
        tasksExeNumberDay: undefined,
        tenantId: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    }
  }
}
</script>
