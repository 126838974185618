<template>
  <!-- 使用弹窗父子页面切换容器组件 -->
  <div class="view-task">
    <Nav :moduleTreeObj="moduleTreeObj" :initParams="initParams" activeModuleKey="taskRecordDetail" @closeModal="handleClose" @viewRoute="handleViewRoute" />
  </div>
</template>

<script>
import Nav from '@/views/container/nav'

export default {
  name: 'ViewTaskModal',
  components: { Nav },
  props: {
    taskId: {
      type: Number,
      default: () => {}
    },
    info: {
      type: Object,
      default: () => {}
    },
    position: {
      type: Object,
      default: () => {}
    },
    isShowModal: {
      type: Boolean,
      default: () => {}
    },
    vehicleId: {
      type: String,
      default: () => {}
    },
    params: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    'info.vehicleId'(newVal, oldVal) {
      this.$emit('closeViewTaskModal')
    },
    params(newVal, oldVal) {
      this.initParams = { ...this.initParams, ...newVal }
    }
  },
  data() {
    return {
      // 配置在弹窗容器组件中所使用的父子组件，并建立组件的父子关系
      moduleTreeObj: {
        key: 'taskRecordDetail',
        title: '任务报告',
        component: () => import('@/views/iot/vehicleTaskRecord/modules/CreateForm'),
        children: [
          {
            key: 'trackDetail',
            title: '轨迹详情',
            component: () => import('@/views/monitor/monitorComponents/trackpage')
          }
        ]
      },
      initParams: {
        vehicleId: this.vehicleId,
        position: this.position,
        info: this.info,
        isShowModal: this.isShowModal,
        width: document.body.clientWidth - 10,
        height: '100%'
      }
    }
  },
  computed: {

  },
  created() {
     console.log('width', this.initParams.width)
     this.initParams = { ...this.initParams, ...this.params }
  },
  methods: {
    handleClose() {
      console.log('ViewTaskContainer Close')
      this.$emit('closeViewTaskModal')
    },
    handleViewRoute(routeId) {
      console.log('handleViewRoute')
      // 由Nav组件传递过来的事件
      this.$emit('viewRoute', routeId)
    }
  },
  beforeDestroy() {}
}
</script>
